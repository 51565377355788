/*!
 * Extension of froala editor font size plugin with font scale value.
 * froala_editor v2.8.1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, jQuery ) {
            if ( jQuery === undefined ) {
                // require('jQuery') returns a factory that requires window to
                // build a jQuery instance, we normalize how we use modules
                // that require this pattern but the window provided is a noop
                // if it's defined (how jquery works)
                if ( typeof window !== 'undefined' ) {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            return factory(jQuery);
        };
    } else {
        // Browser globals
        factory(window.jQuery);
    }
}(function ($) {



  $.extend($.FE.DEFAULTS, {
    fontSize: ['8', '9', '10', '11', '12', '14', '18', '24', '30', '36', '48', '60', '72', '96'],
    fontSizeSelection: false,
    fontSizeDefaultSelection: '12',
    fontSizeUnit: 'px',
    fontScale: '1'
  });

  $.FE.PLUGINS.sdFontSize = function (editor) {
    function apply (val) {
      editor.format.applyStyle('font-size', val);
    }

    function refreshOnShow($btn, $dropdown) {
      var val = $(editor.selection.element()).css('font-size');
      var fontScale =  (! editor.opts.fontScale) ? 1 : parseFloat(editor.opts.fontScale);
      var numVal = parseFloat(val.replace(editor.opts.fontSizeUnit, ''));
      var scaleVal = numVal / fontScale;

      $dropdown.find('.fr-command.fr-active').removeClass('fr-active').attr('aria-selected', false);
      $dropdown.find('.fr-command[data-param1="' + scaleVal + '"]').addClass('fr-active').attr('aria-selected', true);

      var $list = $dropdown.find('.fr-dropdown-list');
      var $active = $dropdown.find('.fr-active').parent();

      if ($active.length) {
        $list.parent().scrollTop($active.offset().top - $list.offset().top - ($list.parent().outerHeight() / 2 - $active.outerHeight() / 2));
      }
      else {
        $list.parent().scrollTop(0);
      }
    }

    function refresh ($btn) {
      if (editor.opts.fontSizeSelection) {
        var fontScale =  (! editor.opts.fontScale) ? 1 : parseFloat(editor.opts.fontScale);
        //var val = editor.helpers.getPX($(editor.selection.element()).css('font-size'));
        var val = parseFloat($(editor.selection.element()).css('font-size'));
        var scaleVal = val / fontScale;

        $btn.find('> span').text(scaleVal);
      }
    }

    return {
      apply: apply,
      refreshOnShow: refreshOnShow,
      refresh: refresh
    }
  }

  // Register the font size command.
  $.FE.RegisterCommand('sdFontSize', {
    type: 'dropdown',
    title: 'Font Size',
    displaySelection: function (editor) {
      return editor.opts.fontSizeSelection;
    },
    displaySelectionWidth: 30,
    defaultSelection: function (editor) {
      return editor.opts.fontSizeDefaultSelection;
    },
    html: function () {
      var c = '<ul class="fr-dropdown-list" role="presentation">';
      var options =  this.opts.fontSize;
      var fontScale =  (! this.opts.fontScale) ? 1 : parseFloat(this.opts.fontScale);

      for (var i = 0; i < options.length; i++) {
        var val = options[i];
        var scaledVal = parseFloat(val) * fontScale;
        c += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="sdFontSize" data-param1="' + scaledVal + this.opts.fontSizeUnit + '" title="' + val + '">' + val + '</a></li>';
      }
      c += '</ul>';

      return c;
    },
    callback: function (cmd, val) {
      this.sdFontSize.apply(val);
    },
    refresh: function ($btn) {
      this.sdFontSize.refresh($btn);
    },
    refreshOnShow: function ($btn, $dropdown) {
      this.sdFontSize.refreshOnShow($btn, $dropdown);
    },
    plugin: 'sdFontSize'
  })

  // Add the font size icon.
  $.FE.DefineIcon('sdFontSize', {
    NAME: 'text-height'
  });

}));
