/*!
 * froala_editor v2.9.1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */

! function(n) {
    "function" == typeof define && define.amd ? define(["jquery"], n) : "object" == typeof module && module.exports ? module.exports = function(e, t) {
        return t === undefined && (t = "undefined" != typeof window ? require("jquery") : require("jquery")(e)), n(t)
    } : n(window.jQuery)
}(function(f) {
    f.extend(f.FE.DEFAULTS, {
        letterSpacings: ["-20", "-15", "-10", "-5", "0", "5", "10", "15", "20"],
        letterSpacingSelection: !1,
        letterSpacingDefaultSelection: "0",
        letterSpacingUnit: "em",
        fontScale: 1
    }), f.FE.PLUGINS.letterSpacing = function(r) {
        return {
            apply: function(e) {
              r.format.applyStyle("letter-spacing", e);
            },
            refreshOnShow: function(e, t) {
                const m = f(r.selection.element()).css("letter-spacing");
                const fontScale =  (! r.opts.fontScale) ? 1 : parseFloat(r.opts.fontScale);
                const numVal = parseFloat(m.replace(r.opts.letterSpacingUnit, ''));

                t.find(".fr-command.fr-active").removeClass("fr-active").attr("aria-selected", !1), t.find('.fr-command[data-param1="' + numVal + r.opts.letterSpacingUnit + '"]').addClass("fr-active").attr("aria-selected", !0);
                let o = t.find(".fr-dropdown-list"),
                    i = t.find(".fr-active").parent();
                i.length ? o.parent().scrollTop(i.offset().top - o.offset().top - (o.parent().outerHeight() / 2 - i.outerHeight() / 2)) : o.parent().scrollTop(0)
            },
            refresh: function(e) {
                if (r.opts.letterSpacingUnit) {
                    const fontScale =  (! r.opts.fontScale) ? 1 : parseFloat(r.opts.fontScale);
                    const val = parseFloat(f(r.selection.element()).css("letter-spacing"));
                    const scaleVal = val / fontScale;
                    const t = ("em" === r.opts.letterSpacingUnit) ? this.pxToEm(scaleVal) : scaleVal;
                    e.find("> span").text(t);
                }
            },
            pxToEm: function (px) {
              const element = r.selection.element();
              const temporaryElement = document.createElement('div');
              temporaryElement.style.setProperty('position', 'absolute', 'important');
              temporaryElement.style.setProperty('visibility', 'hidden', 'important');
              temporaryElement.style.setProperty('font-size', '1em', 'important');
              element.appendChild(temporaryElement);
              const baseFontSize = parseFloat(getComputedStyle(temporaryElement).fontSize);
              temporaryElement.parentNode.removeChild(temporaryElement);
              const em = (parseFloat(px) / baseFontSize).toFixed(3);
              const l = this.closest(em * 1000);
              return (l / 1000) + 'em';
            },
            closest: function (num) {
              const arr = r.opts.letterSpacings;
              let curr = arr[0];
              let diff = Math.abs(num - curr);
              for (let val = 0; val < arr.length; val++) {
                  let newdiff = Math.abs (num - arr[val]);
                  if (newdiff < diff) {
                      diff = newdiff;
                      curr = arr[val];
                  }
              }
              return curr;
            }
        }
    }, f.FE.RegisterCommand("letterSpacing", {
        type: "dropdown",
        title: "Letter spacing",
        displaySelection: function(e) {
            return e.opts.letterSpacingSelection
        },
        displaySelectionWidth: 30,
        defaultSelection: function(e) {
            return e.opts.letterSpacingDefaultSelection
        },
        html: function() {
            const fontScale =  (! this.opts.fontScale) ? 1 : parseFloat(this.opts.fontScale);
            let e;
            for (e = '<ul class="fr-dropdown-list" role="presentation">', t = this.opts.letterSpacings, n = 0; n < t.length; n++) {
                let o = t[n];
                let scaledVal = parseFloat(o) * fontScale;
                let v = "em" === this.opts.letterSpacingUnit ? scaledVal / 1000 : scaledVal;
                e += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="letterSpacing" data-param1="' + v + this.opts.letterSpacingUnit + '" title="' + o + '">' + o + "</a></li>"
            }
            return e += "</ul>"
        },
        callback: function(e, t) {
            this.letterSpacing.apply(t)
        },
        refresh: function(e) {
            this.letterSpacing.refresh(e)
        },
        refreshOnShow: function(e, t) {
            this.letterSpacing.refreshOnShow(e, t)
        },
        plugin: "letterSpacing"
    }), f.FE.DefineIcon("letterSpacing", {
        NAME: "arrows-h"
    })
});
