/*!
 * Plugin to Froala editor for changing editor defaults.
 * froala_editor v2.8.1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, jQuery ) {
            if ( jQuery === undefined ) {
                // require('jQuery') returns a factory that requires window to
                // build a jQuery instance, we normalize how we use modules
                // that require this pattern but the window provided is a noop
                // if it's defined (how jquery works)
                if ( typeof window !== 'undefined' ) {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            return factory(jQuery);
        };
    } else {
        // Browser globals
        factory(window.jQuery);
    }
}(function ($) {



  $.extend($.FE.DEFAULTS, {
    sdStyle: {
      "Default": {
        "title": "Default",
        "fontFamily": "",
        "fontSize": "",
        "color": "",
        "backgroundColor": "",
        "leading": "",
        "letterSpacing": ""
      },
    },
    styleSelection: false,
    styleDefaultSelection: 'Default',
  });

  $.FE.PLUGINS.sdStyle = function (editor) {
    function apply (val) {
      this.applyFontSize(val);
      this.applyLetterSpacing(val);
      this.applyLeading(val);
      this.applyColor(val);
      this.applyFontFamily(val);
      this.applyFontBgColor(val);
    }

    function refreshOnShow($btn, $dropdown) {
      var selected = this.resolveSelected();

      $dropdown.find('.fr-command.fr-active').removeClass('fr-active').attr('aria-selected', false);
      $dropdown.find('.fr-command[data-param1="' + selected + '"]').addClass('fr-active').attr('aria-selected', true);

      var $list = $dropdown.find('.fr-dropdown-list');
      var $active = $dropdown.find('.fr-active').parent();

      if ($active.length) {
        $list.parent().scrollTop($active.offset().top - $list.offset().top - ($list.parent().outerHeight() / 2 - $active.outerHeight() / 2));
      }
      else {
        $list.parent().scrollTop(0);
      }
    }

    function refresh ($btn) {
      if (editor.opts.styleSelection) {
        var selected = this.resolveSelected();

        $btn.find('> span').text(selected);
      }
    }

    function resolveSelected() {
      const fontVal = $(editor.selection.element()).css('font-size');
      const fontScale =  (! editor.opts.fontScale) ? 1 : parseFloat(editor.opts.fontScale);
      const numVal = parseFloat(fontVal.replace(editor.opts.fontSizeUnit, ''));
      const fontScaleVal = numVal / fontScale;
      const font = $(editor.selection.element()).css('font-family');
      const color = $(editor.selection.element()).css('color');
      const options =  editor.opts.sdStyle;

      const entries = Object.entries(options);
      let selected = entries.find(([key, {fontSize, fontFamily, letterSpacing, color: fontColor}]) => {
        return (fontSize == fontScaleVal
          && fontFamily == font
          && this.letterSpacingMatch(letterSpacing)
          && fontColor.replace(/\s/g, "") == color.replace(/\s/g, "")
        );
      });

      return selected ? selected[0] : null;
    }

    function applyFontSize(val) {
      var fontSize = editor.opts.sdStyle[val].fontSize || editor.opts.fontSizeDefaultSelection;
      var fontScale =  (! editor.opts.fontScale) ? 1 : parseFloat(editor.opts.fontScale);
      var fontScaledVal = parseFloat(fontSize) * fontScale;
      var fontUnit = editor.opts.fontSizeUnit;
      editor.format.applyStyle('font-size', fontScaledVal + fontUnit);
    }

    function applyLetterSpacing(val) {
      var letterSpacing = editor.opts.sdStyle[val].letterSpacing || editor.opts.letterSpacings[0];
      var letterSpacingUnit = editor.opts.letterSpacingUnit;
      (letterSpacing) ? editor.format.applyStyle('letter-spacing', letterSpacing + letterSpacingUnit) : editor.format.removeStyle('letter-spacing');
    }

    function applyLeading(val) {
      var leading = editor.opts.sdStyle[val].leading || "";
      editor.selection.save(), editor.html.wrap(!0, !0, !0, !0), editor.selection.restore();
      var t = editor.selection.blocks();
      editor.selection.save();
      for (var n = 0; n < t.length; n++) $(t[n]).css("line-height", leading), "" === $(t[n]).attr("style") && $(t[n]).removeAttr("style");
      editor.html.unwrap(), editor.selection.restore();
    }

    function applyColor(val) {
      var color = editor.opts.sdStyle[val].color || editor.opts.colorsText[0];
      if (! color) {
        editor.format.removeStyle('color');
      } else {
        editor.format.applyStyle('color', color);
      }
    }

    function applyFontFamily(val) {
      var fontFamily = editor.opts.sdStyle[val].fontFamily || Object.keys(editor.opts.fontFamily).find(key => editor.opts.fontFamily[key] === editor.opts.fontFamilyDefaultSelection);
      if (! fontFamily) {
        editor.format.removeStyle('font-family');
      } else {
        editor.format.applyStyle('font-family', fontFamily);
      }
    }

    function applyFontBgColor(val) {
      var fontBackgroundColor = editor.opts.sdStyle[val].backgroundColor || editor.opts.colorsBackground[0];
      if (! fontBackgroundColor) {
        editor.format.removeStyle('background-color');
      } else {
        editor.format.applyStyle('background-color', fontBackgroundColor);
      }
    }

    function letterSpacingMatch(val) {
      let letterSpacing = $(editor.selection.element()).css('letter-spacing');
      letterSpacing = (letterSpacing == 0) ? null : parseFloat(letterSpacing);

      return letterSpacing === val;
    }

    return {
      apply,
      refreshOnShow,
      refresh,
      resolveSelected,
      applyFontSize,
      applyLetterSpacing,
      applyLeading,
      applyColor,
      applyFontFamily,
      applyFontBgColor,
      letterSpacingMatch
    }
  }

  // Register the font size command.
  $.FE.RegisterCommand('sdStyle', {
    type: 'dropdown',
    title: 'Style',
    displaySelection: function (editor) {
      return editor.opts.styleSelection;
    },
    displaySelectionWidth: 30,
    defaultSelection: function (editor) {
      return editor.opts.styleDefaultSelection;
    },
    html: function () {
      var c = '<ul class="fr-dropdown-list" role="presentation">';
      var options =  this.opts.sdStyle;

      for (var o in options) {
        var title = options[o].title;
        var val = o;
        c += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="sdStyle" data-param1="' + val + '" title="' + title + '">' + title + '</a></li>';
      }
      c += '</ul>';

      return c;
    },
    callback: function (cmd, val) {
      this.sdStyle.apply(val);
    },
    refresh: function ($btn) {
      this.sdStyle.refresh($btn);
    },
    refreshOnShow: function ($btn, $dropdown) {
      this.sdStyle.refreshOnShow($btn, $dropdown);
    },
    plugin: 'sdStyle'
  })

  // Add the font size icon.
  $.FE.DefineIcon('sdStyle', {
    NAME: 'magic'
  });

}));
